
import classes from "./infoSection.module.css";

const info = [
    {
        title: "Fast and Cost-Effective",
        description: "Offering competitive rates paired with unmatched speed and service quality",
        backGroundColor: "#031A6B"
    }, {
        title: "Reliability and Expertise",
        description: "Founded in 2006, our seasoned experts, boasting up to 40 years of experience",
        backGroundColor: "#1962AA"
    },
    {
        title: "Worldwide reach",
        description: "Our global network provides comprehensive logistics services in every country around the world",
        backGroundColor: "#2EA9E8"
    },
];

function InfoSection() {
    return (
        <div className={classes["main"]}>
            {info.map(e => {
                return (
                    <div className={classes["section"]} style={{ backgroundColor: e.backGroundColor }} >
                        <h1>{e.title}</h1>
                        <h2>{e.description}</h2>
                    </div>
                );
            })}
        </div>
    );
}

export default InfoSection;