import classes from "./footer.module.css";
import uflLogo from "../../assets/ufl_logo_full.svg";
import uflqr from "../../assets/ufl_qr.svg";
import { Link } from "react-router-dom";


function FooterPage() {

    return (
        <div className={classes["main"]}>
            <div className={classes["left"]}>
                <div className={classes["logo"]}>
                    <img src={uflLogo} alt="UFL" style={{ filter: "brightness(0) invert(1)" }} />
                </div>
                <div className={classes["quickLink"]}>
                    <h4>Quick Links</h4>
                    <Link className={classes["link"]} to="/">Home</Link>
                    <Link className={classes["link"]} to="/contact-us">Contact us </Link>
                    {/* <Link onClick={e => { handleRedirect("careers"); }} className={classes["link"]} to="/careers">Careers </Link> */}
                    {/* <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/universal-freight-logistics/">Linked In</a> */}
                </div>
            </div>
            <div className={classes["right"]}>
                <div className={classes["qr"]}>
                    <img src={uflqr} alt="UFL-QR" style={{ filter: "brightness(0) invert(1)" }} />
                    <p>Save Contact</p>
                </div>
                <div className={classes["contact"]}>
                    <p>E-mail: info@ufl-lb.com</p>
                    <p>Tel: +961 1 582 888</p>
                    <p>Cell: +961 81 861 637 </p>
                    <p>Beirut – Lebanon</p>

                </div>
            </div>
        </div>

    );
}
export default FooterPage;