
import classes from "./cards.module.css";

function Cards() {
    const shipIso = require("../../../assets/iso-ship.png");
    const lclpIso = require("../../../assets/iso-lcl.png");
    const planeIso = require("../../../assets/iso-plane.png");
    const packageIso = require("../../../assets/iso-package.png");

    return (
        <div className={classes["main"]}>
            <div className={`${classes.card} ${classes.a}`}>
                <div className={classes["image"]}>
                    <img src={shipIso} alt="iso ship" />
                </div>
                <div className={classes["content"]}>
                    <div className={classes["text"]}>
                        <h1>Sea Freight</h1>
                        <h2>Best suited for bulky and heavy cargo</h2>
                        <h4>
                            Handling everything from less than container loads (LCL) to full container loads (FCL), Our expertise encompasses oversized, overweight, and hazardous materials, as well as refrigerated perishables. With competitive rates, precise routing, and advanced tracking systems, we ensure your shipments are delivered efficiently and securely, leveraging our team of expert logistics professionals to meet your specific needs.
                        </h4>
                    </div>
                </div>
            </div>
            <div className={`${classes.card} ${classes.b} ${classes.flip}`}>
                <div className={classes["content"]}>
                    <div className={classes["text"]}>
                        <h1>Air Freight</h1>
                        <h2>Best suited for time-sensitive and high-value, fragile cargo.</h2>
                        <h4>
                            We provide a rapid and secure transportation solution that includes special care for fragile, perishable and refrigerated items. We accommodate a spectrum of requirements from small parcels to large freight via our air cargo and express courier services, ensuring fast delivery times and meticulous handling, tailored to preserve the integrity and urgency of your shipments.
                        </h4>
                    </div>
                </div>
                <div className={classes["image"]}>
                    <img src={planeIso} alt="iso plane" />
                </div>
            </div>
            <div className={`${classes.card} ${classes.c}`}>
                <div className={classes["image"]}>
                    <img src={lclpIso} alt="iso lcl" />
                </div>
                <div className={classes["content"]}>
                    <div className={classes["text"]}>
                        <h1>Less Than Container Load</h1>
                        <h2>Best suited for all sorts of regional shipments. </h2>
                        <h4>
                            Our land freight services are designed to accommodate a variety of transport needs within the country or across the Middle East. We offer both full truckload (FTL) and less-than-truckload (LTL) options, making our services flexible and cost-effective without sacrificing the safety and timely delivery of your cargo.
                        </h4>
                    </div>
                </div>
            </div>
            <div className={`${classes.card} ${classes.d} ${classes.flip}`}>
                <div className={classes["content"]}>
                    <div className={classes["text"]}>
                        <h1>End-to-End Delivery</h1>
                        <h2>Your shipment from A to Z.</h2>
                        <h4>
                            From inland transportation to air or sea freight, we ensure seamless handling of your shipments from origin to final destination. Our services encompass customs brokerage, insurance, and more, ensuring all aspects are efficiently managed anywhere in the world.
                        </h4>
                    </div>
                </div>
                <div className={classes["image"]}>
                    <img src={packageIso} alt="iso plane" />
                </div>
            </div>
        </div>


    );
}

export default Cards;