import { Route, Routes, useLocation } from 'react-router-dom';
import classes from './App.module.css';
import MainNavigation from './components/MainNavigation';
import HomePage from './pages/home/HomePage';
import ContactUsPage from './pages/contact-us/contact-us';
import { useEffect, useState } from 'react';
import backdropVideo from './assets/mainBackdrop-video.mp4';
import logoFull from "./assets/ufl_logo_full.svg";
function App() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const location = useLocation();

  return (

    <div className={`${classes.main} ${classes.montserrat_main}`} >
      <div className={classes["navigation"]}>
        <MainNavigation scrollPosition={scrollPosition} />
      </div>
      {location.pathname === '/' && (
        <div className={classes["backdrop"]}>
          <video autoPlay loop playsInline muted>
            <source src={backdropVideo} type="video/mp4" />
          </video>
          <div className={classes["main-logo"]}>
            <img src={logoFull} alt="UFL" style={{ filter: "brightness(0) invert(1)" }} />
          </div>
        </div>

      )}

      <div className={classes["content"]}>
        <Routes>
          <Route path='/' exact element={<HomePage />} />
          <Route path='/contact-us' element={<ContactUsPage />} />
          {/* <Route path='/inquiries' element={<InquiriesPage />} /> */}
          {/* <Route path='/careers' element={< CareersPage />} /> */}
        </Routes>
      </div>
    </div>
  );

}

export default App;
