import { Link, useNavigate } from "react-router-dom";
import classes from "./HomePage.module.css";
import Cards from "./components/cards";
import InfoSection from "./components/infoSection";
import FooterPage from "../components/footer";

function HomePage() {
    return (
        <div className={classes["main"]}>
            <div className={classes["backdrop"]}>
            </div>
            <InfoSection></InfoSection>
            <div className={classes["content"]}>
                <div className={classes["content-title"]}>
                    <h1>Shipping. Worldwide.</h1>
                    <h4>Discover our services.</h4>
                </div>
            </div>
            <Cards></Cards>
            <div className={classes["contact-us"]}>
                <h1>For inquiries, Contact us:</h1>
                <Link to="/contact-us">
                    <button>Contact us</button>
                </Link>
            </div>
            <FooterPage></FooterPage>
        </div>

    );
}

export default HomePage;