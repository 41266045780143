import { Link, useLocation } from "react-router-dom";
import classes from './MainNavigation.module.css';
import uflLogo from "../assets/ufl_logo.svg";
function MainNavigation(props) {
    const location = useLocation();

    return (
        <div className={props.scrollPosition === 0 ? classes["main"] : classes["main-scroll"]}>
            <div className={classes["content"]}>
                <div className={classes["logo"]}>
                    <Link to='/' className={classes["link"]}>
                        <img src={uflLogo} alt="UFL" style={{ transition: ".2s ease-in-out", filter: props.scrollPosition === 0 ? "brightness(0) invert(1)" : "none" }} />
                    </Link>
                </div>
                <div className={classes["options"]} >
                    <Link to='/' className={`${props.scrollPosition === 0 ? classes["link"] : classes["link-scroll"]} ${location.pathname === '/' ? classes["selected"] : ""}`}>Home</Link>
                    {/* <Link to='/about-us' className={classes["link"]} >About Us</Link> */}
                    <Link to='/contact-us' className={`${props.scrollPosition === 0 ? classes["link"] : classes["link-scroll"]} ${location.pathname === '/contact-us' ? classes["selected"] : ""}`}>Contact us</Link>
                    {/* <Link to='/inquiries' className={classes["link"]}>Inquiries</Link> */}
                    {/* <Link to='/careers' className={`${props.scrollPosition === 0 ? classes["link"] : classes["link-scroll"]} ${location.pathname === "careers" ? classes["selected"] : ""}`} onClick={() => setCurrentNavItem("careers")}>Careers</Link> */}
                </div>
            </div>
        </div>
    );
}
export default MainNavigation;