import classes from "./contact-us.module.css";
import pinIcon from "../../assets/pin_icon.svg";
import webIcon from "../../assets/web_icon.svg";
import phoneIcon from "../../assets/phone_icon.svg";
import mobileIcon from "../../assets/mobile_icon.svg";
import FooterPage from "../components/footer";

function ContactUsPage() {

    const headerImage = require("../../assets/contactUs-backdrop.png");
    return (
        <div className={classes["main"]}>
            <div className={classes["header"]}>
                <div className={classes["image"]}>
                    <img src={headerImage} alt="contact-us" />
                </div>
                <div className={classes["text"]}>
                    <h1>CONTACT US</h1>
                </div>
            </div>

            <div className={classes["container"]} >
                <div className={classes["card"]}>
                    <div className={classes["icon"]}>
                        {<img src={pinIcon} alt="pin" style={{ filter: "brightness(0) invert(.75)" }} />}
                    </div>
                    <div className={classes["detail"]}>
                        <h4>location</h4>
                        <p>Medawar, Charles Helou Avenue,</p>
                        <p>Aramouny Building 2nd Floor.</p>
                        <p>Beirut – Lebanon</p>
                    </div>
                </div>
                <div className={classes["card"]}>
                    <div className={classes["icon"]}>
                        <img src={phoneIcon} alt="pin" style={{ filter: "brightness(0) invert(.75)" }} />
                    </div>
                    <div className={classes["detail"]}>
                        <h4>Phone</h4>
                        <p><a href="tel:+9611582888">+961 1 582 888</a></p>
                    </div>
                </div>
                <div className={classes["card"]}>
                    <div className={classes["icon"]}>
                        <img src={mobileIcon} alt="pin" style={{ filter: "brightness(0) invert(.75)" }} />
                    </div>
                    <div className={classes["detail"]}>
                        <h4>Cell/Whatsapp</h4>
                        <p><a href="tel:+96181861637">+961 81 861 637</a></p>
                    </div>
                </div>
                <div className={classes["card"]}>
                    <div className={classes["icon"]}>
                        <img src={webIcon} alt="pin" style={{ filter: "brightness(0) invert(.75)" }} />
                    </div>
                    <div className={classes["detail"]}>
                        <h4>E-Mail</h4>
                        <p><a href="mailto:info@ufl-lb.com">info@ufl-lb.com</a></p>
                    </div>
                </div>
            </div>
            <FooterPage></FooterPage>

            {/*
            <div className={classes["content"]}>
                <div className={classes["left"]}>
                    <div className={classes["icon"]}>
                        <img src={pinIcon} alt="pin" style={{ filter: "brightness(0)" }} />
                    </div>
                    <div className={classes["detail"]}>
                        <p>Medawar, Charles Helou Avenue,</p>
                        <p>Aramouny Building 2nd Floor.</p>
                        <p>Beirut – Lebanon</p>
                    </div>

                </div>
                <div className={classes["right"]}>
                    
                    <p>Cell/Whatsapp: <a href="tel:+96181861637">+961 81 861 637</a></p>
                    <p>E-mail: <a href="mailto:info@ufl-lb.com">info@ufl-lb.com</a></p>
                </div>
            </div> */}
        </div >
    );
};
export default ContactUsPage;;